<template lang="pug">
dialog.modal.fade(ref="modalRefs" aria-labelledby="modalTitle" :class="[{'show': isModalOpen}]")
  .modal-dialog.modal-dialog-scrollable(:class="[{ 'modal-dialog-centered': verticallyCenter }, modalSizeClass]")
    .modal-content
      .modal-header
        button.btn-close.close(type="button" aria-label="Close" @click="modalStore.closeModal()")
        slot(v-if="$slots.header" name="header")
          h5#modalTitle.modal-title Default Modal Title
      .modal-body
        slot
          | Default body content goes here if no slot content is provided.
      .modal-footer
        slot(v-if="$slots.footer" name="footer")
          | Default footer content goes here if no slot content is provided.
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
const modalStore = useModalStore();
// const { isModalOpen } = storeToRefs(modalStore)
const isModalOpen = computed(() => modalStore.isModalOpen);
const modalRefs = ref(null);

function toggleModalState() {
  if (modalRefs.value) {
    if (isModalOpen.value) {
      modalRefs.value.showModal();
    } else {
      setTimeout(() => {
        modalRefs.value.close();
      }, 250);
    }
  }
}

const props = defineProps({
  verticallyCenter: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: '', // default to normal size
    validator: (value) => ['sm', 'lg', 'xl', ''].includes(value), // ensures only these values are accepted
  },
});

const modalSizeClass = computed(() => {
  if (props.size) {
    return `modal-${props.size}`;
  }
  return '';
});

watchEffect(() => {
  toggleModalState();
});
// watch(isModalOpen, toggleModalState, { immediate: true })
</script>

<style lang="scss" scoped>
.modal {
  padding: 0;
  border: 0;
  background: transparent;
  z-index: 10;
  &.fade:not([open]) {
    pointer-events: none;
    transition: opacity 0.2s ease-in;
  }

  &.fade[open] {
    display: block;
    transition: opacity 0.25s ease-out;
  }

  &::backdrop {
    opacity: 0; // by default, the backdrop is invisible
    transition: opacity 0.25s ease-in;
    z-index: 5;
    background: rgba(238, 236, 236, 0.7);
    backdrop-filter: saturate(180%) blur(10px);
  }

  &.show::backdrop {
    transition: opacity 0.2s ease-out;
    opacity: 0.9;
  }
}

dialog:modal {
  max-width: unset;
  max-height: unset;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-dialog {
  width: var(--ti-modal-width) !important;
}
</style>
